import { AppState } from '../types/store';
import { PaymentMethod, ProductType } from '../types';
import { getOrderedProductType } from '../helpers/checkout';

export const getAllowedPaymentMethods = (state: AppState) =>
  state?.checkoutPage?.checkout?.paymentMethods;

// only enable coupons for CORE package checkout
export const getEnableCoupons = (state: AppState) => {
  return (
    state?.checkoutPage?.checkout?.enableCoupons &&
    state?.checkoutPage?.checkout?.order &&
    getOrderedProductType(state?.checkoutPage?.checkout?.order) ===
      ProductType.CORE_PACKAGE
  );
};

export const getPaymentMethod = (state: AppState) =>
  state?.checkoutPage?.activePaymentMethod ?? PaymentMethod.CREDIT_CARD;

export const getExtraNote = (state: AppState) =>
  state?.checkoutPage?.form?.note || '';

export const getGiftIsAnon = (state: AppState) =>
  state?.checkoutPage?.form?.giftIsAnon ? true : false;

export const getGiftUser = (state: AppState) =>
  state?.checkoutPage?.checkout?.giftUser;

export const getOrderInfo = (state: AppState) =>
  state?.checkoutPage?.checkout?.order;

export const getGiftType = (state: AppState) => state?.checkoutPage?.giftType;

export const getErrorCode = (state: AppState) => state?.checkoutPage?.errorCode;

export const getCheckout = (state: AppState) => state?.checkoutPage?.checkout;

export const getStripePublishableKey = (state: AppState) =>
  state?.checkoutPage?.checkout?.stripePublishableKey;

export const getIsWalletsNotSupported = (state: AppState) => {
  if (!isExpressCheckoutEnabled(state)) {
    return true;
  }
  return state?.checkoutPage?.isWalletsNotSupported;
};

export const isExpressCheckoutEnabled = (state: AppState) => {
  const methods = state.checkoutPage?.checkout?.paymentMethods || [];
  return (
    methods.includes(PaymentMethod.GOOGLE_PAY) ||
    methods.includes(PaymentMethod.APPLE_PAY)
  );
};
